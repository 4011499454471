import React from "react";
import { toast } from "sonner";
import { useAuthenticatedFetch } from "../../hooks/use-authenticated-fetch";
import { useAI } from "../../contexts/AIContext";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const Settings = () => {
  const authenticatedFetch = useAuthenticatedFetch();
  const { agents, isLoadingAgents, fetchUserData, error: aiError, currentOrg } = useAI();

  const handleChange = (e) => { 
    const { name, value } = e.target;
    const updatedSettings = {
      ...currentOrg.settings,
      [name]: name === 'defaultAgentId' ? (value ? parseInt(value, 10) : null) : value
    };
    
    handleSubmit(updatedSettings);
  };

  const handleSubmit = async (updatedSettings) => {
    try {
      await authenticatedFetch('/api/orgSettings', {
        method: 'POST',
        body: { settings: updatedSettings },
      });
      toast.success("Settings updated successfully!");
      fetchUserData(); // Refresh user data to update settings
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error(error.message || "Failed to update settings.");
    }
  };

  const handleGetAPIKey = async () => {
    try {
      const { apiKey } = await authenticatedFetch('/api/getAPIKey');
      
      await navigator.clipboard.writeText(apiKey);
      toast.success("API key copied to clipboard!");
    } catch (error) {
      console.error("Error getting API key:", error);
      toast.error(error.message || "Failed to get API key");
    }
  };

  if (isLoadingAgents || !currentOrg) return <div>Loading...</div>;

  // Use settings directly from currentOrg
  const settings = currentOrg.settings || {};

  return (
    <div className="max-w-3xl mx-auto my-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Settings</h2>
      
      {/* API Key Section */}
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">API Access</h3>
        <button
          onClick={handleGetAPIKey}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <ClipboardDocumentIcon className="w-5 h-5 mr-2" />
          Copy API Key to Clipboard
        </button>
        <p className="mt-2 text-sm text-gray-500">
          Click to copy your API key. Keep this secure and don't share it with others. Currently, API keys are valid for 6 months.
        </p>
      </div>

      {/* Agent Preferences Section */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Preferences</h3>
        <div className="space-y-6">
          <div>
            <label htmlFor="defaultAgentId" className="block text-sm font-medium text-gray-700">
              Default Agent
            </label>
            <select
              id="defaultAgentId"
              name="defaultAgentId"
              value={settings.defaultAgentId || ""}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Select an agent...</option>
              {agents.map(agent => (
                <option key={agent.id} value={agent.id}>
                  {agent.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;