export function getSupportedModels(connections) {
  if (!connections) {
    console.error("No connections provided");
    return [];
  }

  const allModels = [
    {
      name: "gpt-4o",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:gpt-4o",
      tokens: 128000,
      outputTokens: 8192,
      vision: true,
    },
    {
      name: "gpt-4o-mini",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:gpt-4o-mini",
      tokens: 128000,
      outputTokens: 8192,
      vision: true,
    },
    {
      name: "o3-mini",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:o3-mini",
      tokens: 200000,
      outputTokens: 100000,
      vision: false,
    },
    {
      name: "o1",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:o1",
      tokens: 200000,
      outputTokens: 100000,
      vision: true,
    },
    {
      name: "o1-preview",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:o1-preview",
      tokens: 128000,
      outputTokens: 32768,
      vision: false,
    },
    {
      name: "o1-mini",
      provider: "OpenAI",
      providerKey: "openai",
      key: "openai:o1-mini",
      tokens: 128000,
      outputTokens: 65536,
      vision: false,
    },
    {
      name: "claude-3-5-sonnet-latest",
      provider: "Anthropic",
      providerKey: "anthropic",
      key: "anthropic:claude-3-5-sonnet-20240620",
      tokens: 200000,
      outputTokens: 8192,
      vision: true,
    },
    {
      name: "claude-3-5-sonnet-20240620",
      provider: "Anthropic",
      providerKey: "anthropic",
      key: "anthropic:claude-3-5-sonnet-20240620",
      tokens: 200000,
      outputTokens: 8192,
      vision: true,
    },
    {
      name: "gemini-1.5-flash",
      provider: "Google",
      providerKey: "google",
      key: "google:gemini-1.5-flash",
      tokens: 1048576,
      outputTokens: 8192,
      vision: true,
    },
    {
      name: "gemini-1.5-pro",
      provider: "Google",
      providerKey: "google",
      key: "google:gemini-1.5-pro",
      tokens: 2097152,
      outputTokens: 8192,
      vision: true,
    },
    {
      name: connections.azure_openai?.DEPLOYMENT_NAME,
      provider: "Azure OpenAI",
      providerKey: "azure_openai",
      key: `azure:${connections.azure_openai?.DEPLOYMENT_NAME}`,
      tokens: 128000,
      outputTokens: 8192,
      vision: true,
    },
    {
      name: "grok-2-latest",
      provider: "xAI",
      providerKey: "xai",
      key: "xai:grok-2-latest",
      tokens: 131072,
      vision: false,
    },
  ];

  return allModels.filter((model) => connections[model.providerKey]);
}